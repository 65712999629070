import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconArrowLeft, LazyIconAwardFive, LazyIconAwardFour, LazyIconAwardOne, LazyIconAwardThree, LazyIconAwardTwo, LazyIconBack, LazyIconCart, LazyIconCartArrow, LazyIconChevronDown, LazyIconClock, LazyIconClose, LazyIconCloseGray, LazyIconDirectionsWalk, LazyIconDisabled, LazyIconDoctorLike, LazyIconDown, LazyIconHamburgerMenu, LazyIconHeart, LazyIconHeartRed, LazyIconHomeSmile, LazyIconLogo, LazyIconLogoNapopravku, LazyIconLogoProdoctorov, LazyIconLogoSber, LazyIconLogoYandex, LazyIconMagnifer, LazyIconMail, LazyIconMapMarker, LazyIconMapOpen, LazyIconMastercard, LazyIconMenuContacts, LazyIconMenuPromotions, LazyIconMinus, LazyIconMir, LazyIconNext, LazyIconParking, LazyIconPauseButtonModalGallery, LazyIconPencil, LazyIconPhone, LazyIconPhoneHeader, LazyIconPlayButton, LazyIconPlayButtonModalGallery, LazyIconPlus, LazyIconReview, LazyIconReviewAllPlatforms, LazyIconReviewArrows, LazyIconReviewDoubleGIS, LazyIconReviewEastClinic, LazyIconReviewGoogleMaps, LazyIconReviewHands, LazyIconReviewNaPopravku, LazyIconReviewProDoctor, LazyIconReviewSberHealth, LazyIconReviewStar, LazyIconReviewUsers, LazyIconReviewYandexMaps, LazyIconRouting, LazyIconShieldCheck, LazyIconShieldStar, LazyIconStethoscope, LazyIconTrash, LazyIconUser, LazyIconVisa, LazyIconVolumeOff, LazyIconVolumeOn, LazyIconWhatsApp, LazyIconWork } from '#components'
const lazyGlobalComponents = [
  ["IconArrowLeft", LazyIconArrowLeft],
["IconAwardFive", LazyIconAwardFive],
["IconAwardFour", LazyIconAwardFour],
["IconAwardOne", LazyIconAwardOne],
["IconAwardThree", LazyIconAwardThree],
["IconAwardTwo", LazyIconAwardTwo],
["IconBack", LazyIconBack],
["IconCart", LazyIconCart],
["IconCartArrow", LazyIconCartArrow],
["IconChevronDown", LazyIconChevronDown],
["IconClock", LazyIconClock],
["IconClose", LazyIconClose],
["IconCloseGray", LazyIconCloseGray],
["IconDirectionsWalk", LazyIconDirectionsWalk],
["IconDisabled", LazyIconDisabled],
["IconDoctorLike", LazyIconDoctorLike],
["IconDown", LazyIconDown],
["IconHamburgerMenu", LazyIconHamburgerMenu],
["IconHeart", LazyIconHeart],
["IconHeartRed", LazyIconHeartRed],
["IconHomeSmile", LazyIconHomeSmile],
["IconLogo", LazyIconLogo],
["IconLogoNapopravku", LazyIconLogoNapopravku],
["IconLogoProdoctorov", LazyIconLogoProdoctorov],
["IconLogoSber", LazyIconLogoSber],
["IconLogoYandex", LazyIconLogoYandex],
["IconMagnifer", LazyIconMagnifer],
["IconMail", LazyIconMail],
["IconMapMarker", LazyIconMapMarker],
["IconMapOpen", LazyIconMapOpen],
["IconMastercard", LazyIconMastercard],
["IconMenuContacts", LazyIconMenuContacts],
["IconMenuPromotions", LazyIconMenuPromotions],
["IconMinus", LazyIconMinus],
["IconMir", LazyIconMir],
["IconNext", LazyIconNext],
["IconParking", LazyIconParking],
["IconPauseButtonModalGallery", LazyIconPauseButtonModalGallery],
["IconPencil", LazyIconPencil],
["IconPhone", LazyIconPhone],
["IconPhoneHeader", LazyIconPhoneHeader],
["IconPlayButton", LazyIconPlayButton],
["IconPlayButtonModalGallery", LazyIconPlayButtonModalGallery],
["IconPlus", LazyIconPlus],
["IconReview", LazyIconReview],
["IconReviewAllPlatforms", LazyIconReviewAllPlatforms],
["IconReviewArrows", LazyIconReviewArrows],
["IconReviewDoubleGIS", LazyIconReviewDoubleGIS],
["IconReviewEastClinic", LazyIconReviewEastClinic],
["IconReviewGoogleMaps", LazyIconReviewGoogleMaps],
["IconReviewHands", LazyIconReviewHands],
["IconReviewNaPopravku", LazyIconReviewNaPopravku],
["IconReviewProDoctor", LazyIconReviewProDoctor],
["IconReviewSberHealth", LazyIconReviewSberHealth],
["IconReviewStar", LazyIconReviewStar],
["IconReviewUsers", LazyIconReviewUsers],
["IconReviewYandexMaps", LazyIconReviewYandexMaps],
["IconRouting", LazyIconRouting],
["IconShieldCheck", LazyIconShieldCheck],
["IconShieldStar", LazyIconShieldStar],
["IconStethoscope", LazyIconStethoscope],
["IconTrash", LazyIconTrash],
["IconUser", LazyIconUser],
["IconVisa", LazyIconVisa],
["IconVolumeOff", LazyIconVolumeOff],
["IconVolumeOn", LazyIconVolumeOn],
["IconWhatsApp", LazyIconWhatsApp],
["IconWork", LazyIconWork],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

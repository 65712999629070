import { PageInfoService } from '../EastclinicVueApi';

import type { IPageInfoRequest, IPageInfo } from '../EastclinicVueApi';

export const usePageInfoStore = defineStore('pageInfoStore', () => {
  const mainPhone:string = '+74951747474';
  const vacancyPhone:string = '+74951871791';
  const vacancyFormattedPhone:string = '+7 (495) 187-17-91';
  const formattedMainPhone:string = '+7 (495) 174-74-74';
  const whatsAppPhone:{ [key: number]: string } = {
    0: 'https://wa.me/79150800638',
    1: 'https://wa.me/79150800638',
    2: 'https://wa.me/79169200710', // mytishchi
    3: 'https://wa.me/79175851624', // luybercy
    6: 'https://wa.me/79175841608', // belyaevo
    18: 'https://wa.me/79932623498', // odintsovo
    23: 'https://wa.me/79154501608', // univer
    45: 'https://wa.me/79932743530' // volokolamskaya
  };

  interface Agreement {
    text: string;
    url: string;
  }

  interface Agreements {
    agreement: Agreement;
    personal: Agreement;
    yandex: Agreement;
  }
  const agreements: Agreements = {
    agreement: {
      text: 'Пользовательское соглашения на предварительное бронирование времени услуги',
      url: 'https://eastclinic.ru/assets-new/docs/agreement-1.pdf'
    },
    personal: {
      text: 'Политика обеспечения конфиденциальности',
      url: 'https://eastclinic.ru/assets-new/docs/soglasie-na-obrabotku.pdf'
    },
    yandex: {
      text: 'Политика обработки персональных данных',
      url: 'https://yandex.ru/legal/smartcaptcha_notice/'
    }
  };

  interface License {
    num: string;
    date: string;
    title: string;
    url: string;
  }
  type LicenseCollection = {
    [key: number]: License;
  };
  const licenses: LicenseCollection = {
    1: {
      title: 'Ист Клиник на Соколе',
      num: 'Л041-01137-77/00338859',
      date: '19.09.2018',
      url: 'https://eastclinic.ru/assets-new/docs/licenzii/licenziya-sokol.pdf'
    },
    23: {
      title: 'Ист Клиник на Университете',
      num: 'Л041-01137-77/00327211;',
      date: '23.06.2015',
      url: 'https://eastclinic.ru/assets-new/docs/licenzii/licenziya-universitet.pdf'
    }, // uni
    6: {
      title: 'Ист Клиник в Беляево',
      num: 'Л041-01137-77/00587874',
      date: '10.03.2020',
      url: 'https://eastclinic.ru/assets-new/docs/licenzii/licenziya-belyaevo.pdf'
    }, // bel
    45: {
      title: 'Ист Клиник в Митино',
      num: 'Л041-01137-77/00349029',
      date: '13.05.2020',
      url: 'https://eastclinic.ru/assets-new/docs/licenzii/licenziya-mitino.pdf'
    }, // mit
    3: {
      title: 'Ист Клиник в Люберцах',
      num: 'Л041-01137-77/00358016',
      date: '17.11.2020',
      url: 'https://eastclinic.ru/assets-new/docs/licenzii/licenziya-lyubercy.pdf'
    }, // lub
    18: {
      title: 'Ист Клиник в Одинцово',
      num: ' Л041-01137-77/00587874',
      date: '10.03.2020',
      url: 'https://eastclinic.ru/assets-new/docs/licenzii/licenziya-odintsovo.pdf'
    }, // odin
    2: {
      title: 'Ист Клиник в Мытищах',
      num: 'Л041-01162-50/00326797',
      date: '28.08.2018',
      url: 'https://eastclinic.ru/assets-new/docs/licenzii/licenziya-mytishchi.pdf'
    } // myt
  };

  const sessionId = ref<string|undefined>('');
  const currentClinicId = ref<number|null>(null);
  const pageInfo = ref<IPageInfo|null>(null);

  const fetch = async (request:IPageInfoRequest) => {
    request.component = 'east';
    request.action = 'getPageInfo';
    const res = await PageInfoService.fetch(request);

    if (res.code) {
      if (res.code === 404) {
        throw createError({
          statusCode: 404,
          message: 'Страница не найдена'
        });
      } else if (res.code === 500) {
        throw createError({
          statusCode: 500,
          message: 'Ошибка на странице'
        });
      }
    }
    if (res.redirectUrl) {
      await navigateTo(res.redirectUrl);
      // useNuxtApp().$router.push(res.redirectUrl);
    }
    pageInfo.value = res.data.resource;
    sessionId.value = res.sessionId;
    currentClinicId.value = res.data.currentClinicId ?? null;
    return res;
  };
  const getPageType = () => computed(() => pageInfo.value?.type);

  const getLinkPhone = computed(() => (isVacancyPage()) ? 'tel:' + vacancyPhone : 'tel:' + mainPhone);
  const getFormattedMainPhone = computed(() => (isVacancyPage()) ? vacancyFormattedPhone : formattedMainPhone);
  const getMainPhone = computed(() => (isVacancyPage()) ? mainPhone : vacancyPhone);
  const getWhatsAppPhone = computed(() =>
    (currentClinicId.value && whatsAppPhone[currentClinicId.value]) ? whatsAppPhone[currentClinicId.value] : whatsAppPhone[0]);
  const priceServices = computed(() => pageInfo.value?.catalogRootServices);

  const isVacancyPage = () => {
    return (pageInfo.value?.id === 12 || (pageInfo.value?.parents[12]));
  };
  return {
    fetch,
    pageInfo,
    sessionId,
    currentClinicId,
    getPageType,
    getLinkPhone,
    getFormattedMainPhone,
    getMainPhone,
    getWhatsAppPhone,
    priceServices,
    agreements,
    licenses
  };
});
